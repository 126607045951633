a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}

.onboard{
    position: relative;
    height: 100vh;
    width: 100%;
    @include media-breakpoint-down(sm) {
        height: 100vh;
     }

     .flow-chart-wrapper{
        @include media-breakpoint-up(sm) {
            // top: 150px;
            position: relative;
         }
     }

    .overlay{
        height: 100%;
        position: absolute;
        width: 100%;
        background-color: rgba(0,0,0,0.4);
        .map-wrapper.overlay{
            opacity: 0.4;
        }
        @include media-breakpoint-down(sm) {
           height: 100%;
           background-color: white
        }
    }
    .onboarding-rectangle{   
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 100vh;
        width: 812px !important;
        display: block !important;
        &.onboard2{
            width: 100vw !important;
        }
        &.onboard1{
            background: #F5F8FB;
        }
        
        .onboard-logo{
            height: auto;
            max-width: 175px;
            max-height: 50px;
            width: auto;
            margin: 0 245px 60px;
            @include media-breakpoint-down(sm) {
                margin: 0 35px 40px;
            }
        }
        
        .onboard-block{
            position: absolute;
            width: 100%;
            padding: 0 110px;
            // display: none;
            @include media-breakpoint-down(md) {
                padding: 0;
                height: 100vh;
            }
            .referral-link{
                border: 1px solid lightgray !important;
                padding: 0 10px;
                margin-top: 10px;
                width: 60%;
                height: 27px;
            }
            .company-icon{
                margin: 5px auto 32px;
                width: 30px;
            }
            .title{
                font-size: 24px;
                font-weight: bold;
                line-height: 28px;
                text-align: center;
                margin-bottom: 5px;
            }
            .sub-title{
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                margin: 0 auto 95px;
                max-width: 500px;
                padding : 0 20px;
                @include media-breakpoint-up(sm) {
                    margin-bottom: 130px;
                    padding: 0 6px 0;
                }
                &.space{
                    margin-bottom: 165px;
                }
            }
            .content-block{
                background-color: white !important;
                @include media-breakpoint-down(sm) {
                    padding: 15px;
                    padding-bottom: 0;
                    position: absolute;
                    bottom: 0;
                    border-radius: 30px 30px 0 0;
                    height: 450px;
                }
                .sent{
                    color: #ED992D !important;
                    margin-left: 5px;
                    font-size: 14px;
                    font-weight: 700;
                }
                .status-feed-send{
                    margin-top: 15px;
                    font-size: 14px;
                    font-weight: 700;
                    display: inline-block;
                    width: auto;
                    &:hover,&.sent{
                        color: #ED992D !important;
                    }
                }
                &.white-box-secondary{
                    padding: 25px;
                    padding-bottom: 0;
                    @include media-breakpoint-down(md) {
                        padding: 30px 25px 0;
                    }
                }
                .img-block{
                    position: absolute;
                    display: inline-block;
                    height: 27px;
                    margin-top: 10px;
                    padding: 0px 6px;
                    border: 1px solid;
                    .copy-icon{
                        display: inline;
                        height: 16px;
                        cursor: pointer;   
                        margin-bottom: 2px;                         
                    }
                }
                
                .heading-share{
                    &::before, &::after {
                        content:'\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
                        text-decoration: line-through;
                        margin: auto 0.5em;
                        color: #E0E0E0;
                    }
                }
                .welcome-text{
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 20px;
                }
                .pm-message{
                    border-radius: 4px;
                    margin-top: 30px;
                    padding: 6px;
                    border: 2px solid #FFCC00 !important;
                    -webkit-transition: box-shadow linear 1s;
                    transition: box-shadow linear 1s !important;
                    box-shadow: 0 0 10px #FFCC00 !important;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 20px;
                }
                .documents-button-status-feed{
                    margin-top: 35px;
                    font-size: 18px !important;
                    &:hover,&:active{
                        background-color: #ED992D;
                    }
                }
                .glance-col{
                    display: inline-flex;
                    padding: 25px 0px 25px 35px;
                    border-bottom: 1px solid lightgray;
                    @include media-breakpoint-down(md) {
                        padding: 15px 0px 0px 60px;
                        border-right: 0 !important;
                        &.panel{
                            padding-left: 52px;
                        }
                    }
                    .glance-icon{
                        height: 32px;
                    }
                    .glance-text{
                        color: #4A4A4A;
                        margin-left: 20px;
                        font-size: 24px;
                        font-weight: 300;
                        line-height: 28px;
                        margin-right: auto;
                        p{
                            font-size: 18px;
                            font-weight: 300;
                            line-height: 21px;
                        }
                    }
                }
            }
        }

       
        @include media-breakpoint-down(md) {
            width: 100% !important;
            top:0;
            height:auto;
            padding-top: 0px;
            padding-bottom: 135px;
            min-height: 100vh;
        }

        .say-hello-to{
            height: auto;
            color: #4A4A4A;
            font-size: 24px;
            font-weight: bold;
            line-height: 28px;
            margin: 0 238px;
            @include media-breakpoint-down(sm) {
                margin: 0 74px 15px 35px;
                text-align: inherit;
            }
            &.heading{
                padding: 0 205px;
                font-weight: 400;
                margin-top: 40px;
                @include media-breakpoint-down(md) {
                    padding-top: 20px;
                }
                @include media-breakpoint-down(sm) {
                    padding: 0 30px;
                }
            }
        }
        .logo{
            height: 65px;
            width: 166.87px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
        .mobile-logo{
            height: 60px;
            display: block;
            margin: 0 auto;
            padding-top: 20px;
        }
        .onboard-text{
            margin: 0 238px;
            margin-top: 15px;
            color: #4A4A4A;
            font-size: 18px;
            font-weight: 300;
            line-height: 28px;
            @include media-breakpoint-down(sm) {
                margin: 0 35px;
                margin: 0 74px 0 35px;
                text-align: initial;;
            }
        }

        .text{
            text-align: justify;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
        }
        
        img.button-logo {
            position: absolute;
            cursor: pointer;
            z-index: 2;
            &.prev{
                transform: rotate(180deg) !important;
                left: 20px;
             top: 50%;
             transform: translateY(-50%);
             @include media-breakpoint-down(md) {
                display: none;
            }
            }
            &.next{
                right:20px;
             top: 50%;
             transform: translateY(-50%);
             @include media-breakpoint-down(md) {
                display: none;
            }
            }                              
        }
        .begin-button{
            left: 50%;
            transform: translateX(-50%);
            @include media-breakpoint-down(md) {
                margin-top: 20px;
            }
        }
        .footer-button{
            display: inline-block;
            width: 45px;
            &.next-icon{
                    display: block;
                    float: right;
                    margin-top: 30px;
                    width: 37px;
                @include media-breakpoint-up(sm) {
                    display: none;
                }
               
            }
        }
        .onboard-step{
            height: 95px;
            width: 100%;
            @include media-breakpoint-down(md) {
                bottom: 0;
                position: absolute;
                right: 0px;
            }
            .referral-link{
                border: 1px solid lightgray !important;
                padding: 0 10px;
                margin-top: 10px;
                width: 60%;
                height: 27px;
                cursor: pointer !important;
            }
            .skip-button{
                margin: 0 auto;
                line-height: 95px;
                font-size: 15px;
                text-align: center;
                width: 100%;
                @include media-breakpoint-down(md) {
                    margin: 0;
                    width: 100%;
                    padding: 0px 15px;
                }
                .skip-text{
                    line-height: 95px;
                    cursor: pointer;
                    float: left;
                    color: #bfbfbf;
                    margin: 0;
                }
            }
        }
    }
}

.slick-list, .slick-slider, .slick-track {
    position: inherit !important;
    // height: 100%;
}