.camp-dashboard-wrapper {
	margin-top: 150px;
}

.ant-popover.campaign-status-action{
	margin-top: 8px;
	.ant-popover-inner-content{
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
		padding: 4px 30px;
		.action-name{
			color: #000000;
			font-size: 11px;
			font-weight: 300;
			cursor: pointer;
			line-height: 19px;
			&:hover{
				color: #00142D;
				font-weight: 400;
			}
		}
	}
	
}
