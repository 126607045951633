body {
    background: $pmbackground;
}


.pm-home-owner-wrapper {
    @include media-breakpoint-down(sm) {
        padding: $padd10;
    }
}


.pm-home-owner-wrapper .nav-tabs .nav-link {
    border: 0px;
    border-right: 1px solid $labelborder;
    border-radius: 0;
    font-size: $font18;
    color: black;
    opacity: .9;
}

.pm-home-owner-wrapper .nav-tabs {
    border: 0px;

    @include media-breakpoint-down(sm) {
        margin: $marg20 0;
    }
}

.pm-home-owner-wrapper .nav-tabs .nav-link.active,
.pm-home-owner-wrapper .nav-tabs .nav-item.show .nav-link,
.pm-home-owner-wrapper .nav-tabs .nav-link:hover,
.pm-home-owner-wrapper .nav-tabs .nav-link:focus {
    color: $ltblue2;
    text-decoration: underline;
}