.rating-box {
  margin-top: 60px;
  cursor: pointer;
  @include media-breakpoint-down(sm) {
    width: 70px;
    margin: 10px;
    float: left;
  }
  .stats {
    font-weight: 900;
    font-size: 24px;
  }
}

.rating-box .image-icon {
  margin-top: $marg17;
  margin-bottom: $marg3;
  @include media-breakpoint-down(sm) {
    margin-top: $marg5;
    font-size: $font12;
  }
}

.rating-box .image-icon img {
  height: 40px;
  margin-bottom: 10px;
  @include media-breakpoint-down(sm) {
    width: auto;
  }
}

.referral span {
  position: relative;
  padding-left: $padd30;
}

.referral span::before {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $yellowbg;
  position: absolute;
  top: 0px;
  left: 0;
  content: '';
}

.active-account span {
  position: relative;
  padding-left: $padd30;
  @include media-breakpoint-down(sm) {
    padding-left: $padd10;
  }
}

.active-account::before {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $ltblue2;
  position: absolute;
  top: 3px;
  right: 130px;
  content: '';
}

.referral,
.active-account {
  margin-top: $marg20;
  font-size: 14px;
}
.referral {
  margin-left: -10px;
}

div.row.chart-label {
  border-top: solid 1px #e9e9e9;
  margin: 0 auto;
  margin-left: 45px;
  width: 100%;
  margin-left: 0;
}

.progress-wrapper {
  color: rgba(0, 0, 0, 0.87);
  font-size: $font14;
  margin-bottom: 38px;
}
.progress-wrapper:last-child {
  margin-bottom: 29px;
}

.progress-wrapper .progress-name {
  max-width: 145px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;

  @include media-breakpoint-down(sm) {
    max-width: 38%;
  }
}

.progress-wrapper .progress-raing {
  max-width: 50px;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  @include media-breakpoint-down(sm) {
    max-width: 10%;
  }
}

.progress-wrapper .progress-wrap {
  max-width: calc(100% - 210px);

  @include media-breakpoint-down(sm) {
    max-width: 50%;
  }
}

.progress-wrapper .progress {
  height: 7px;
  margin-top: $marg10;
  color: rgba(0, 0, 0, 0.87);
}

.progress-wrapper .progress-bar {
  background: $ltblue;
}

.social-media-box .icon-text img {
  margin-right: $marg40;
}

.social-media-box .icon-text {
  font-size: $font14;
  color: black;
  font-weight: 300;
}

.social-media-box .number-box {
  font-size: $font24;
  color: $dkgrye;
  font-weight: 300;
}

.social-media-box small {
  font-size: $font14;
  color: $dkgrye;
  font-weight: 300;
}

.social-media-box {
  margin-top: $marg50;
  min-height: 75px;
}

.mobile-heading {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;
    font-size: $font16;
    margin: $marg20 $marg3;
  }
}
.social-text {
  width: 20%;
  display: inline-block;
  text-align: right;
  float: right;
}
.social-icon-box {
  width: 80%;
  float: left;
}

.dashboard-nav-wrapper .navbar-brand {
  background: url(../Assets/Images/bodhi_logo.svg);
  margin: 0 0;
  cursor: pointer;
  background-repeat: no-repeat;
  &.homeownerView {
    background-size: contain;
    height: 44px;
    padding: 0;
    width: 270px;
  }
  &.installerView {
    background: url(../Assets/Images/Bodhi_Yellow_on_Blue.jpeg);
    width: 100%;
    height: 75px;
    background-size: 200px;
    background-position: center;
    background-repeat: no-repeat;
  }
  &.default {
    width: 118px;
    height: 45px;
    background-size: 118px 40px;
    margin-left: 20px;
    margin-top: 15px;
  }

  @include media-breakpoint-down(sm) {
    background: url(../Assets/Images/bothi-mobile-logo.png);
    height: 37px;
    width: 34px;
    margin: auto;
    margin-top: 4px;
    background-size: 34px;
    background-repeat: no-repeat;
    padding: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &.publicReferral {
      background: url(../Assets/Images/bodhi_small.svg);
      background-repeat: no-repeat;
      width: 72px;
    }
    &.homeowner {
      display: none;
    }
    &.homeownerView {
      height: 33px;
      width: 33px;
      background-size: 33px;
      top: 0;
    }
  }
}

.metrics-table-box {
  box-sizing: border-box;
  height: 30.67%;
  width: 92.38%;
  margin-left: 3.81%;

  .table-box-header {
    position: relative;
    width: 100%;
    color: #000000;
    height: auto;
    float: left;
    min-width: 92% !important;
    border: 1px solid #e9e9e9;
    border-radius: 4px 4px 0 0;
    background-color: #ffffff;
    box-shadow: 0 -1px 6px 0 rgba(176, 186, 197, 0.5) !important;
    .table-type {
      opacity: 0.9;
      float: left;
      margin-left: 17px;
      font-size: 18px;
      line-height: 21px;
      margin: 19px 0 14px 17px;
      font-weight: 400;
    }

    .column-header {
      position: relative;
      float: right;
      margin: 8px 17px 0 0;
      color: #7a7a7a;
      border-color: #ccc;
      &:hover {
        background-color: $silver1;
      }
    }
    .column-box {
      position: absolute;
      right: 0;
      top: 43px;
      box-sizing: border-box;
      height: auto;
      width: 305px;
      border: 1px solid #9b9b9b;
      background-color: white;
      z-index: 1;
      padding-bottom: 16px;
      .drop-up-arrow {
        position: absolute;
        top: -13px;
        right: 45px;
        background: url(../Assets/Images/menuCaret.png) no-repeat;
        height: 13px;
        width: 76px;
        background-size: 35%;
      }
      .column-name {
        width: 120px !important;
        font-size: 12px;
        float: left;
        margin: 22px 0 0 20px;
        &[data-id='Default'] {
          background: #e0e0e0;
          color: #7a7a7a;
          border-color: #7a7a7a;
        }
      }
    }
  }
  .table-responsive {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #e9e9e9;
    border-radius: 0 0 4px 4px;
    background-color: #ffffff;
    table.metrics-table {
      height: auto;
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
  .line {
    position: relative;
    box-sizing: border-box;
    width: calc(100% - 32px);
    border-top: 1px solid #e0e0e0;
    margin: 0px 16px 17px;
    float: left;
  }
}

table.metrics-table th.table-header {
  height: 43px;
  padding: 0 25px 0;
  box-sizing: border-box;
  color: #4a4a4a;

  font-size: 14px;
  line-height: 38px;
  font-weight: 700;
  min-width: 160px !important;
  .header-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 45px;
    height: 100%;
    display: inline-block;
    // max-width: 110px;
    // display: flex;
    // white-space: pre-wrap;
    // align-items: center;
    // float: left;
  }
  .filter-sort-icon {
    position: relative;
    margin: 17px -5px 0 10px;
    box-sizing: border-box;
    width: 50px;
    display: inline-block;
    .sort-icon {
      float: left;
      margin: 0 3px;
      cursor: pointer;
      height: 7px;
    }
    .filter-block {
      position: relative;
      height: auto;
      .drop-up-arrow {
        position: absolute;
        top: -20px;
        left: -45px;
        background: url(../Assets/Images/menuCaret.png) no-repeat;
        height: 16px;
        width: 79px;
        background-size: 35%;
        transform: rotate(-90deg);
      }
      .filter-icon {
        float: right;
        cursor: pointer;
      }
      .filter-box {
        position: absolute;
        left: 60px;
        top: -10px;
        box-sizing: border-box;
        height: auto;
        width: 228px;
        border: 1px solid #9b9b9b;
        background-color: #ffffff;
        z-index: 1;
        padding: 6px 16px;
        .header {
          font-size: 13px;
          font-weight: bold;
          width: 100%;
          height: 30px;
          line-height: 30px;
        }

        .form-control {
          height: auto;
          width: 187px;
          border: 1px solid #e9e9e9 !important;
          padding: 3px 23px 0 5px;
          margin-top: 0;
          font-size: 12px;
          border-radius: 3px !important;
        }
        .filter-button {
          position: relative;
          height: 25px;
          border: 1px solid #ffd110;
          background-color: #ffd110;
          color: #00142d;
          font-size: 12px;
          line-height: 25px;
          padding: 0 20px;
          border-radius: 4px !important;
          cursor: pointer;
          margin-bottom: 18px;
          margin-top: 17px;
          left: 25%;
          transform: translateX(-50%);
          font-weight: 600;
          &.cancel {
            margin-right: 8px;
            background-color: #fff;
          }
        }
      }
    }
  }
}
.metrics-table {
  th {
    font-weight: 600;
  }
  tr:nth-of-type(odd) {
    background-color: $greyLight;
  }
}

.metrics-row {
  height: auto !important;
  width: 100%;
  border-color: transparent;
  &:hover {
    box-shadow: inset 0 2px 6px 0 rgba(176, 186, 197, 0.5) !important;
  }
  .edit-option {
    float: right;
    .dynamic-button {
      height: 18px;
      margin-right: 22px;
      cursor: pointer;
      z-index: 2;
    }
  }
  &.deletedelectricutility {
    font-style: italic;
  }

  .metrics-data {
    box-sizing: border-box;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.21px;
    line-height: 2;
    padding: 8px 25px;
    min-width: 172px !important;
    word-wrap: break-word;
    max-width: 330px;
    white-space: normal;
    .alert_severity{
      height: 15px;
      width: 15px;
      background-color: #FF6F47;
      border-radius: 50%;
      margin: auto;
      &.Yellow{
        background-color: #E3AC00;
      }
    }
    &.error{
      color: #E1464E;
      margin-right: 5px;
    }
    &.success{
      color: #1F7323;
      margin-right: 5px;
    }
    .edit-box {
      border: none;
      border-bottom: 1px solid lightgray;
    }
    .fa-icon{
      margin-right: 5px;
    }
    .action-btn {
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
      cursor: pointer;
      &:focus,
      &.ant-popover-open {
        color: #00142d;
      }
    }
    .sync-button {
      margin-left: 10px;
      background: #00142d;
      color: white;
      height: 30px;
      padding: 3px 10px;
      border-radius: 2px;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

.pagination-box {
  position: relative;
  height: 32px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  margin: 30px 0;
  line-height: 32px;
  .ant-select-selector {
    border: 0 none !important;
  }
  .ant-select-selection-item {
    color: #00142d;
    font-weight: 300;
  }
}

.pagination-link-button .link-button {
  position: relative;
  float: left;
  cursor: pointer;
  margin-top: 5px;
}

.pagination-link-button .page-number {
  position: relative;
  float: left;
  margin: 0 5px;
  color: #00142d;
  font-size: 14px;
  font-weight: 300;
}

.pagination-box .pagination-limit-button {
  position: relative;
  float: right;
  height: 100%;
  margin-left: 10px;
  font-weight: 400;
  .ant-select-selection {
    border: 0 !important;
    font-weight: 300;
    color: #00142d;
  }
}

.pagination-link-button img {
  position: relative;
  float: left;
  margin: 1px 9px 0;
  width: 10px;
  &.rightIcon {
    float: right;
  }
}

.pagination-option {
  .ant-select-selection .ant-select-arrow {
    top: 30%;
    right: 2px !important;
  }
  svg.selectIcon {
    transform: rotate(90deg);
    width: 6px;
  }
  &.ant-select-open {
    svg.selectIcon {
      transform: rotate(-90deg);
    }
  }
}
.data-empty {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 30px;
  text-align: center;
  padding-bottom: 15px;
}

.metrics-table-box .data-empty {
  position: initial;
  float: none;
  padding-top: 0;
  padding-bottom: 0;
  .table-header {
    height: auto;
    padding: 10px 0;
    font-weight: 400;
    button.btn {
      height: auto;
    }
  }
}

.homeowner-view-wrapper {
  margin-bottom: 90px;
}
