body,
html {
  height: 100%;
}
*:focus {
  outline: 0;
}

/* Hide the browser's default checkbox */

a:hover {
  text-decoration: none;
}

/**
 * TODO: this is a hack until we have dynamic handling for this using styled-components
 */
body.homeowner-mobile-scroll div.mob-menu-untether.compose {
  button.compose-message-cta {
    width: 40px;
    height: 40px;
    left: -80px;
    padding: 0;
    border: 0 none;
    background-color: transparent;
    .tethered {
      display: none;
    }
    .untethered {
      display: inline-block;
      width: 60px;
      height: 60px;
      background: url('../Assets/Images/compose_icon.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      top: -10px;
      right: -10px;
    }
  }
}

/* Create a custom checkbox */

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

label {
  text-transform: inherit;
  font-weight: 400;
  font-size: $font14;
  margin-bottom: $marg2;
}

.form-control {
  border: 0px;
  border-radius: 0px;
  font-size: $font14;
  padding-left: $padd0;
}

input[type='tel'] {
  border-bottom: 1px solid #d9d9d9 !important;
  text-overflow: ellipsis;
  font-size: 14px !important;
  font-weight: 300;
  border-bottom: 1px solid #d9d9d9 !important;
  &.border-0 {
    border: none !important;
    background-color: initial;
  }
}

p.certain-search-item-count {
  margin: 0;
}

.btn-secondary + .btn-primary {
  margin-left: 20px;
}

.login-footer {
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: $font12;
  color: white;
}

.white-box .table-box {
  overflow: auto;
  max-width: 100%;
  height: 382px;
  overflow-y: auto;
}

.white-box .heading {
  margin: 33px $marg30 25px $marg30;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 0;
  @include media-breakpoint-down(sm) {
    padding: $padd10;
    margin: $marg10;
    padding-bottom: 0;
    padding-left: 0;
  }
}

.white-box .table th,
.table td {
  border-top: 0px;
  border-bottom: 0px;
  text-align: left;
}

.white-box .table th:first-child,
.table td:first-child {
  padding-left: $padd30;
}

.white-box .table-box td {
  opacity: 0.7;
}

.white-box .drop-dwon-btn button {
  padding: $padd3 $padd10;
  font-size: $font12;
  background: $labelborder;
  border-radius: 2px;
  border-color: $labelborder;
  color: $dkgrye;
  font-size: $font14;
}

.white-box .drop-dwon-btn .dropdown-toggle-split {
  border-left: 1px solid white;
}

.dashboard-nav-wrapper .nav-add-link {
  .nav-link {
    color: #4a4a4a;
    height: 30px;
    padding: 3px 0;
    border-radius: 2px;
    font-size: 14px;
    margin-top: 17px;
    margin-right: 22px;
    margin-left: 5px;
    display: block !important;
    &.border-active {
      font-weight: 700;
      border-bottom: 4px solid #ff6f47;
      border-radius: 0;
      text-align: left;
    }
    &.addhomeowner {
      background-color: #ffd110;
      height: 40px;
      padding: 0px 18px;
      border-radius: 10px;
      margin-top: 8px;
      line-height: 40px;
      margin-right: 12px;
      color: #00142d;
      font-size: 14px;
      border: 1px solid #ffd110;
      font-weight: 700;
      box-sizing: border-box;
      &:hover {
        color: #00142d;
        background-color: #ffdb40;
        border: 1px solid #ffdb40;
        box-sizing: border-box;
      }
    }
    span {
      width: AUTO;
      display: inline-block;
    }
  }
}
.dashboard-nav-wrapper .bg-light {
  @include media-breakpoint-down(sm) {
    padding: $padd0;
    margin-bottom: 0;
    border-bottom: 0px;
    background: #f5f8fb !important;
    text-align: center;
    border: 0;
    height: 70px;
  }
}

.dashboard-nav-wrapper .navbar-toggler {
  @include media-breakpoint-down(sm) {
    position: absolute;
    right: 0;
  }
}

.dashboard-nav-wrapper .navbar-toggler {
  margin-right: $marg20;
}

.dashboard-nav-wrapper .navbar-expand-lg .navbar-nav .dropdown-menu {
  right: 0;
  width: auto;
  min-width: 230px;
  left: inherit;
  border-radius: 0;
  margin-top: 14px;
  padding: $padd10;
  &.homeowner {
    padding: 0 25px;
    width: 300px;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.dashboard-profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  margin: 0 auto;
  @include media-breakpoint-down(md) {
    margin-bottom: 15px;
  }
}

.dropdown-menu .dashboard-profile-image::before {
  display: none;
}

.dashboard-nav-wrapper .navbar-light .navbar-nav .nav-link {
  display: inline-flex;
}

.dashboard-nav-wrapper .navbar-light .navbar-nav .nav-add-link {
  @include media-breakpoint-down(md) {
    margin: $marg20 0 $marg20 0;
  }
}

.dashboard-nav-wrapper .drop-up-arrow {
  position: absolute;
  top: -9px;
  right: -53px;
  background: url(../Assets/Images/menuCaret.png) no-repeat;
  height: 52px;
  width: 73px;
  z-index: 999;
  background-size: 24%;
}

.dashboard-nav-wrapper .dashboard-profile-name {
  line-height: 35px;
  padding: 0 $padd10;
  font-size: $font14;
  color: #000;
  cursor: pointer;
  white-space: nowrap;
  min-width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: table-header-group;
  margin-top: 2px;
}

.nav-item.dropdown {
  position: relative;
}

.nav-item.dropdown::before {
  position: absolute;
  left: 0px;
  height: 45px;
  width: 1px;
  background: $silver1;
  top: 8px;
  content: '';
}
.dashboard-nav-wrapper .dropdown-toggle::after {
  content: url(../Assets/Images/settingCart.svg);
  border: 0;
  margin-top: 5px;
}
.show .dropdown-toggle::after {
  // content: url(../Assets/Images/settingCartActive.svg);
  border: 0;
}

.dashboard-nav-wrapper .dashboar-search .form-inline .form-control {
  height: 30px;
  border: 1px solid $labelborder;
  margin-right: -4px !important;
  width: 300px;
  padding-right: $padd35;
  @include media-breakpoint-down(md) {
    width: 250px;
  }
}

.dashboard-nav-wrapper .dashboar-search .btn {
  position: absolute;
  top: 0px;
  left: -4px;
  height: 30px;
  margin: 0px;
  border: none;
  padding: 0 6px;
  @include media-breakpoint-down(sm) {
    top: 0px;
    margin-top: 0 !important;
  }
}

.dashboard-nav-wrapper .dashboar-search form {
  margin-top: 12px;
}

.dashboard-nav-wrapper .navbar-collapse {
  z-index: 1;
  @include media-breakpoint-down(md) {
    padding: $padd15;
    border-bottom: 1px solid $labelborder;
    border-radius: 0px;
    margin-top: $marg11;
    overflow-x: hidden;
    background: white;
  }
}

.white-r20 {
  margin-right: $marg20;
  @include media-breakpoint-down(sm) {
    margin-right: $marg0;
  }
}

.white-l20 {
  margin-left: $marg20;
  @include media-breakpoint-down(sm) {
    margin-left: $marg0;
  }
}

.display-none {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.dashboar-search .search-wrap {
  display: inline-flex;
  input[type='search'] {
    width: inherit;
  }
  .ant-select-selection__placeholder {
    color: #4a4a4a !important;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-select-auto-complete.ant-select .ant-select-selection {
    border: none !important;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    background-color: #fbfbfb;
  }
  .ant-select-search__field__wrap {
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    border-style: none solid none none;
  }
  input {
    border: none !important;
    color: #4a4a4a !important;
    text-transform: none !important;
  }
}

.dashboard-nav-wrapper .dropdown-menu .media {
  padding: 5px 5px 5px 0px;
  border-bottom: 1px solid #e0e0e0;
  margin: 0;
  color: #4a4a4a;
  &.homeowner {
    padding: 0;
  }
  h5 {
    font-size: $font14;
    margin-bottom: $marg0;
    color: $dkgrye;
    a {
      font-weight: 700;
    }
  }
  a {
    color: $dkgrye;
    padding-left: $padd10;
    font-weight: 300;
  }
}

.dashboard-nav-wrapper .dropdown-menu .dropdown-item {
  color: #313541;
  font-size: 14px;
  margin: 10px 0 0 10px;
  padding: 0;
  &:hover {
    color: #ff6f47;
  }
  &.homeowner {
    padding-top: $padd20;
    padding-bottom: $padd20;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    color: #4a4a4a;
    &:last-child {
      border: none;
    }
  }
  .text {
    display: block;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin-left: 45px;
  }
  .dropHeader {
    color: #4a4a4a;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
  }
}

.dashboard-nav-wrapper .dropdown-menu .dropdown-item .drop-icon {
  margin-right: $marg20;
}

.white-box-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../Assets/Images/select-drop-down-arrow.png);
  background-repeat: no-repeat;
  width: 160px;
  text-indent: 0.01px;
  text-overflow: '';
  padding: $padd5 $padd30 $padd5 $padd15;
  border: 1px solid $labelborder;
  color: $dkgrye;
  border-radius: 4px;
  font-size: $font12;
  background-color: $labelborder;
  background-position: 100% 0px;
  line-height: 20px;
  padding-bottom: 0;
  padding-top: 0;
  height: 25px;
}

table .btn {
  padding: $padd2 $padd20 $padd2 $padd20;
  height: 22px;
}

.btn-warning {
  color: white;
  &:hover {
    color: white;
  }
}

.section-from-box .form-group {
  position: relative;
}

.section-from-box .form-group .error {
  color: $error;
}

.error-close {
  position: absolute;
  top: 20px;
  right: 0;
}

/* PM homw -owner */

.marg-two-box {
  margin: $marg80 $marg125 $marg0 $marg125;
  @include respond-to('large') {
    margin: $marg40 $marg10 $marg0 $marg10;
  }
  @include media-breakpoint-down(sm) {
    margin: $marg0;
    padding-bottom: 30px;
  }
}

.two-box-wrapper .progress-wrapp {
  width: 380px;
  @include respond-to('large') {
    width: auto;
  }
}

.two-box-wrapper .next-level-wrapp {
  width: 100%;
}

.white-box-secondary {
  background-color: white;
  border: 1px solid $blackbrd;
  border-radius: 5px;
  @include respond-to('sm') {
    margin-top: -29px;
    margin-bottom: 41px !important;
  }
}

.white-box-secondary .heading {
  font-size: $font14;
  color: $black8;
  text-align: center;
  border: 0px;
  margin: $marg0;
  padding: $padd15 0 $padd20 0;
  font-weight: bold;
}

.white-box-secondary .journey-progress {
  font-size: $font18;
  font-weight: 500;
  @include media-breakpoint-down(sm) {
    padding-left: 15px;
    margin-top: -6px;
    padding-bottom: 0;
  }
}

.white-box-secondary .journey-progress p {
  margin-bottom: $marg0;
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
  margin-top: $marg0;
  cursor: pointer;
  word-wrap: break-word;
  a {
    color: inherit;
    font-weight: initial;
    &.disabled-link {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.btn-default {
  font-size: $font12;
  border-radius: 2px;
}

.white-box-secondary .journey-progress .greytext {
  color: $ltgrey2;
}

.white-box-secondary .journey-progress .bluetext {
  color: $ltblue2;
}

.bluetext {
  color: $ltblue2;
}

.slick-list {
  -webkit-transition: height 250ms linear;
}

.blue-bg-box {
  background-color: #00142d;
  padding: $padd15;
  color: white;
  .heading-small {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
  }
  h2 {
    font-size: $font22;
    line-height: 22px;
    margin: $marg0 $marg0 $marg20 $marg0;
  }
  p {
    font-size: $font18;
    line-height: 26px;
  }
  .info-box {
    border-top: 2px solid white;
    padding-top: $padd20;
    padding-bottom: $padd20;
  }
  .closeIcon {
    position: absolute;
    top: 14px;
    right: 25px;
    cursor: pointer;
    opacity: 0;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  .text-center {
    color: inherit;
    &.slider-heading2 {
      opacity: 0.9;
      font-size: 20px;
      font-weight: 500;
      margin: 30px 0 0;
    }
  }
  padding: $padd0;
  border-radius: 4px;
  position: relative;
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
  h2 {
    padding: $padd50 12px 0;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    font-weight: 400;
    text-transform: none;
  }
  .numbering-box {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    padding: 13px $padd15 $padd25 $padd15;
    width: 95%;
    display: inline-flex;
    margin: 0 auto;
    .number-content {
      border-radius: 100px;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.2);
      width: 65px;
      margin: auto;
      font-size: 15px;
      letter-spacing: -0.24px;
      line-height: 20px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.gallery-box img {
  border-left: 1px solid white;
  border-bottom: 1px solid white;
}

.white-box-secondary .dashboard-profile-image::before {
  display: none;
}

.media {
  margin-bottom: $marg25;
  position: relative;
  .media-body {
    padding-left: $padd15;
  }
  h5 {
    font-size: $font18;
    color: $black8;
    margin-bottom: $marg0;
    line-height: 21px;
    font-weight: 700;
  }
  a {
    font-size: $font14;
    color: $black8;
    margin-bottom: 5px;
    font-weight: 400;
  }
}

.white-box-secondary .share-link {
  position: absolute;
  right: 0px;
}

.white-box-secondary .date-reply-box {
  a {
    font-size: $font14;
    color: $ltblue2;
    font-weight: 700;
    line-height: 20px;
  }
  p {
    font-size: $font14;
    line-height: 20px;
    font-weight: 300;
    color: #000;
    margin-bottom: 0px;
  }
}
.whtie-heading-accordion .card-header {
  background-color: white;
  border: 0px;
  color: $black8;
  text-align: center;
  padding-top: 21px;
  padding-bottom: 22px;
}

.whtie-heading-accordion .card-header .arrow-wrap {
  font-size: $font26;
  position: relative;
}
.whtie-heading-accordion .card-header .arrow-wrap .down-arrow,
.whtie-heading-accordion .card-header .arrow-wrap .up-arrow {
  position: absolute;
  left: -50px;
}
.whtie-heading-accordion .card-header h5 .up-arra {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  text-align: center;
  margin-top: 10px;
  display: inherit;
  font-weight: 500;
}
.whtie-heading-accordion .card-header h5 .up-arra:hover {
  text-decoration: none;
}

.whtie-heading-accordion .card-header h5 .up-arra .up-arrow {
  display: block;
}

.whtie-heading-accordion .card-header h5 .up-arra .down-arrow {
  display: none;
}

.whtie-heading-accordion .card-header h5 .collapsed .up-arrow {
  display: none;
}

.whtie-heading-accordion .card-header h5 .collapsed .down-arrow {
  display: block;
}

.whtie-heading-accordion .card {
  margin-bottom: $marg20;
}

.hori-vert-cetner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.updatePassword {
  background-color: #f6cb04 !important;
  border: none;
  height: 30px;
  width: auto;
  font-weight: 500;
  padding: 0 15px;
  &:hover {
    background-color: #f6cb04 !important;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.border0 {
  border: 0px !important;
}
.border-r0 {
  border-radius: 0px !important;
}

h2 {
  font-size: $font24;
}

h3 {
  font-size: $font18;
}

.text-trns-upp {
  text-transform: uppercase;
}

.text-trns-cap {
  text-transform: capitalize;
}

/* portal -css start */

.dashboard-nav-wrapper.portal-header-wrapper .bg-light {
  border-top: 10px solid $yellowbg;
}

.portal-nav-wrapper .navbar-nav .nav-item {
  line-height: 40px;
  border-right: 1px solid $labelborder;
}

.portal-nav-wrapper .navbar-nav .nav-item:last-child {
  border-right: 0px;
}

.portal-nav-wrapper .navbar-nav .nav-item a {
  font-size: $font18;
  font-weight: normal;
  color: black;
  padding: 0 $padd20;
}

.portal-nav-wrapper .navbar-nav .nav-item a:hover,
.portal-nav-wrapper .navbar-nav .nav-item a.active {
  color: $blue3;
  text-decoration: underline;
}

.yellowtext {
  color: $yellowbg;
}

.border0 {
  @include media-breakpoint-down(sm) {
    border: 0px;
  }
}

.center-border-text {
  @include media-breakpoint-down(sm) {
    position: relative;
    margin: auto;
    width: 100%;
    margin-top: $marg60;
  }
}

.center-border-text::before {
  content: '';
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 200px;
  height: 1px;
  background: $ltgrey1;
}

.center-border-text div {
  width: 100px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  background: white;
  top: -12px;
  text-align: center;
}

.mobile-heading {
  font-size: $font18;
  text-align: center;
  margin-bottom: $marg30;
  font-weight: 500;
}

/* marg padd */

.marg0 {
  margin: $marg0 !important;
}
.margb0 {
  margin-bottom: $marg0;
}
.margb10 {
  margin-bottom: $marg10;
}
.margb15 {
  margin-bottom: $marg15;
}

.margt70 {
  margin-top: $marg40;
  margin-bottom: $marg40;
  display: block;
}
.margt80 {
  margin-top: $marg80;
}
.margt20 {
  margin-top: $marg20 !important;
}

.margb35 {
  margin-bottom: $marg35;
}

.margb70 {
  margin-bottom: $marg70;
  @include media-breakpoint-down(sm) {
    margin-bottom: $marg30;
    &.view {
      margin-top: -80px;
    }
  }
}
.margb90 {
  margin-bottom: $marg90;
}
.paddb70 {
  padding-bottom: 70px;
}
.margt0 {
  margin-top: $marg0 !important;
}
.margt15 {
  margin-top: 15px !important;
}

.margb0 {
  margin-bottom: $marg0 !important;
}
.margt40 {
  margin-top: $marg40;
}
.margb30 {
  margin-bottom: $marg30 !important;
}
.margb80 {
  margin-bottom: $marg80 !important;
}
.margl20 {
  margin-left: $marg20;
}

.margr20 {
  margin-right: $marg20;
}
.margr15 {
  margin-right: 15px;
}
.margt60 {
  margin-top: $marg25;
  &.setting-mob {
    @include media-breakpoint-down(sm) {
      padding-left: 3px;
      &.logout {
        padding-right: 73px;
      }
    }
  }
}

.paddl0 {
  padding-left: $padd0 !important;
  // display: flex;
  // justify-content: flex-end;
  // margin-left: auto;
}
.padding-30 {
  padding: 30px !important;
}

.paddr0 {
  padding-right: $padd0 !important;
}
.paddl10 {
  padding-left: $padd10;
}

.paddl20 {
  padding-left: $padd20;
}
.paddr20 {
  padding-right: $padd20;
}
.paddl25 {
  padding-left: $padd25;
}
.paddr25 {
  padding-right: $padd25;
}

.pddl30 {
  padding-left: $padd30;
}

.paddr50 {
  padding-right: $padd50;
}

.paddl50 {
  padding-left: $padd50;
}

.pddr30 {
  padding-right: $padd30;
}

.paddb50 {
  padding-bottom: $padd50;
}
.paddb0 {
  padding-bottom: 0;
}
.pddb30 {
  padding-bottom: $padd30;
}

.paddall25 {
  padding: $padd25;
}

.padd15 {
  padding: $padd15;
}

.padd0 {
  padding: $padd0;
}

.padd25 {
  padding: $padd25;
}
.paddt0 {
  padding-top: $padd0;
}
.paddt10 {
  padding-top: $padd10 !important;
}

.paddt20 {
  padding-top: $padd20;
}

.paddt25 {
  padding-top: $padd25;
}
.paddt30 {
  padding-top: $padd30;
}

.paddt15 {
  padding-top: $padd15;
}

.border0 {
  @include media-breakpoint-down(sm) {
    border: 0px;
  }
}

.mobile-view {
  display: none;
  @include media-breakpoint-down(xs) {
    display: block;
    .mobile-summary {
      margin: 25px 0 25px 15px !important;
    }
    .month-name {
      font-size: 20px;
      font-weight: bold;
      padding: 0 15px;
      margin-bottom: -5px;
      display: block;
    }
  }
}

.center-border-text {
  @include media-breakpoint-down(sm) {
    position: relative;
    margin: auto;
    width: 100%;
    margin-top: $marg60;
  }
}

.center-border-text::before {
  content: '';
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 200px;
  height: 1px;
  background: $ltgrey1;
}

.center-border-text div {
  width: 100px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  background: white;
  top: -12px;
  text-align: center;
}

.mobile-heading {
  font-size: $font18;
  text-align: center;
  margin-bottom: $marg30;
  font-weight: 500;
}

.desktop-Logo div {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
.mobile-Logo div {
  display: none !important;
  @include media-breakpoint-down(sm) {
    display: inline-block !important;
    width: 150px;
    height: 30px;
  }
}

// system at a glance
.white-box {
  border: 1px solid $ltgrey1;

  @include media-breakpoint-down(sm) {
    height: auto;
    margin: $marg0;
    margin-bottom: $marg30;
  }
}

.height24 {
  height: 24px !important;
}
.margin-bottom25 {
  margin-bottom: 25px !important;
}

#collapseOne.collapse:not(.show) {
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.container .create-new-account-wrapper {
  @include media-breakpoint-down(sm) {
    margin-top: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 100px;
    margin-bottom: -30px;
    .homeowner-layout{
      height: calc(100vh - 50px);
    }

  }
}
.progress-wrapp .white-box-secondary {
  @include media-breakpoint-down(sm) {
    border: 0;
  }
}

.height-delayand-track {
  height: calc(100% - 37px);
  overflow-y: hidden;
  @include media-breakpoint-down(sm) {
    height: 460px;
    overflow-y: auto;
    padding-bottom: 0 !important;
  }
}

.pmName {
  color: #4a4a4a;
  font-weight: 500;
}
.pmId {
  font-weight: 300;
  font-size: 14px;
}

.resource-bg {
  width: 327px;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  &.upcoming {
    background: #9b9b9b !important;
    color: white !important;
  }
}

.contact-wrapperdate-box {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.test-meter-connection-btn {
  height: 30px;
  width: 100%;
  border: 1px solid $ltblue2;
  border-radius: 2px !important;
  color: $ltblue2;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  background: none;
  cursor: pointer;
  margin-top: 10px;
  opacity: 1;
  transition: all 0.4s;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.test_connection_result {
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
}
.test_connection_result-valid {
  color: #417505 !important;
}
.test_connection_result-invalid {
  color: #d0021b !important;
}

.mob-enable {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block !important;
  }
}
.display-mob {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.bodhi-popover {
  width: 340px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 #d8dde2;
  font-size: 12px;
  .ant-popover-content {
    color: #00142d;
    background-color: #ccc;
    border-radius: 10px;
    border-color: #00142d;
    margin-top: 10px;
    letter-spacing: 0.2px;
    .ant-popover-arrow {
      border-top-color: inherit;
      border-left-color: inherit;
      .ant-popover-arrow-content {
        --antd-arrow-background-color: #ccc;
      }
    }
    .ant-popover-inner {
      background-color: inherit;
      border-radius: 10px;
      .ant-popover-inner-content {
        color: inherit;
        padding: 20px 15px 2px;
        button.ant-btn.button {
          font-size: 14px;
          line-height: 17px;
          font-family: 'HarmoniaSansProCyrBold';
          color: inherit;
          background-color: inherit;
          border-color: inherit;
          border-radius: 10px !important;
          padding: 0px 44px;
          margin: 20px auto;
          height: 42px;
          display: block;
          &:hover {
            color: #00142d;
            background-color: #ffffff;
            border-color: #ffffff;
          }
        }
      }
    }
  }
}

.installer__searchImageIcon {
  display: flex;
  align-items: center;
  background-color: #fbfbfb;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.installer {
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    color: #7a7a7a;
  }

  .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input {
    padding-right: 10px;
    height: 100%;
  }
}

.align-center {
  float: none !important;
  display: block !important;
  margin: 0 auto !important;
  cursor: pointer;
}

.homeowner-message-initials {
  margin-bottom: 10px;
}

