.sys-notification-wrapper {
	.left-menu-box {
		position: relative;
		padding: 42px 30px 30px 62px;
		&::after {
			position: absolute;
			content: '';
			height: 100%;
			width: 2px;
			background-color: #f5f8fb;
			top: 0;
			right: 0;
			text-align: left;
		}
	}
	.left-menu {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
			color: #6a6a6a;
			font-size: 14px;
			line-height: 18px;
			padding: 10px 0;
			font-weight: 700;
			a {
				color: #6a6a6a;
				&:hover {
					color: #00142d;
				}
				.collpaseble {
					margin-right: 5px;
					width: 10px;
					margin-bottom: 3px;
					height: 7px;
					&.rotate {
						transform: rotate(90deg);
					}
				}
			}
			a.active {
				color: #00142d;
			}
		}
	}
	.right-content-box {
		.content-box {
			border: 1px solid #dfe6ee;
			border-radius: 10px;
			.templateName{
				padding: 17px 30px;
				margin-bottom: 0;
				border-bottom: 1px solid #DFE6EE;
				font-weight: 700;
				font-size: 14px;
			}
			.trigger-box {
				border-bottom: 1px solid #dfe6ee;
				color: #000;
				line-height: 25px;
				padding: 10px 15px 5px;
				.phase-name {
					width: 60%;
					padding: 0 5px;
					font-weight: 400;
				}
				.edit-icon {
					height: 17px;
					margin: 0 7px;
				}
				.red-warning{
					line-height: 20px;
					color: #E1464E;
					margin: 4px 0 0 5px;
				}
			}

			.fleet-icon {
				margin-right: 10px;
				height: 25px;
				width: auto;
				padding: 0;
			}
			strong {
				padding: 4px 4px 0 0;
				width: auto;
			}
			.fleet {
				text-transform: inherit;
				font-size: $font14;
				margin-bottom: $marg2;
				min-width: 75px;
				font-weight: 300;
				letter-spacing: 0;
				color: #000;
				padding-left: 3px;
				padding-top: 4px;
				width: auto;
			}
			h3 {
				background-color: #e9e9e9;
				color: #4a4a4a;
				font-size: 14px;
				line-height: 18px;
				padding: 5px 15px 0;
				border-bottom: 1px solid #dfe6ee;
			}
			.content-section-box {
				font-size: 14px;
				line-height: 18px;
				width: 450px;
				margin: 0 auto;
				padding: 10px 10px 40px 10px;
				.form-control {
					border-left: 0;
					border-right: 0;
					border-top: 0;
					border-radius: 0;
					box-shadow: none;
					max-width: 61px;
					font-size: 14px;
					line-height: 18px;
					&:focus {
						border-left: 0;
						border-right: 0;
						border-top: 0;
						border-radius: 0;
						box-shadow: none;
						max-width: 61px;
						font-size: 14px;
						line-height: 18px;
					}
				}
			}
		}
		.add-journey-step{

			.add-step {
				width: 20px;
				margin-right: 5px;
				cursor: pointer;
				margin-left: -10px;
			}
		}
	}
}

.journey-option {
	label {
		display: block !important;
		margin: 15px 0;
	}
}

.my-handle {
	cursor: move;
	cursor: -webkit-grabbing;
	font-size: 21px;
	margin-right: 10px;
}

.p0 {
	padding: 0;
}
.tabs-wrapper {
	.tab-content {
		> .active {
			display: block;
			opacity: 1;

			.ant-checkbox-wrapper.form-check-input {
				border: 0 none;
			}
		}
	}
	.nav-tabs {
		background-color: $greyLight;
		.nav-item {
			flex-grow: 1;
			text-align: center;
			font-size: 14px;
			line-height: 16px;
			font-weight: bold;
		}
		.nav-link {
			border: 0;
			cursor: pointer;
		}

		.nav-item.show {
			.nav-link {
				border-radius: 0;
				color: #00142d;
			}
		}
		.nav-link.active {
			border-radius: 0;
			color: #ff6f47;
			border-bottom: 1px solid #dee2e6 !important;
			margin-bottom: 0;
		}
	}
}
.mb0 {
	margin: 0;
}
.tab-content-wrapper {
	padding: 40px 15px 15px 15px;
	.timeCondition{
		margin-top: 20px;
		b::before,b::after{
			display: inline-block;
    		content: "";
    		border-top: 2px solid #DFE6EE;
    		width: 50px;
    		margin: 3px 11px;
		}
		.form-control{
			height: auto !important;
		}
	}
	.filter-select {
		&::placeholder {
			color: #bfbfbf;
		}

		&:-ms-input-placeholder {
			color: #bfbfbf;
		}

		&.personlize {
			color: #00142d;
		}
		.ant-select-selection__rendered {
			.ant-select-search__field {
				width: 100% !important;
				border: none !important;
			}
		}
	}

	.status-feed-option label.ant-radio-wrapper {
		width: 100%;
		font-weight: 300;
	}

	.max-length-warning {
		width: 100%;
		display: inline-flex;
		align-items: flex-end;
		height: 100%;
		justify-content: flex-start;
		padding-right: 12px;
		padding-bottom: 5px;
		color: #7a7a7a;
		.composer-warning-text {
		  color: #ff6f47;
		}
	  }

	select {
		font-size: 14px;
		line-height: 18px;
		border-radius: 0;
		box-shadow: none;
		border-color: #ced4da;
		padding: 0px 15px;
		height: 25px;
		&:focus {
			font-size: 14px;
			line-height: 18px;
			border-radius: 0;
			box-shadow: none;
			border-color: #ced4da;
			padding: 0px 15px;
			height: 25px;
		}
	}
}
.add-new-box {
	padding: 0 15px 0 15px;
	color: #00142d;
	a {
		color: #00142d;
		font-size: 14px;
		line-height: 14px;
		&.disable-link {
			pointer-events: none;
		}
	}
}
.add-trigger {
	width: 15px;
	margin-right: 6px;
}
.plus {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	text-align: center;
	line-height: 15px;
	margin-right: 5px;
	cursor: pointer;
}
.delete-heading {
	text-align: right;
	float: right;
	color: #6a6a6a;
	font-size: 14px;
	line-height: 14px;
	cursor: pointer;
	margin-top: 5px;
	width: auto;
	a {
		color: #6a6a6a;
	}
	img {
		margin-right: 6px;
	}
}
.border-box-add {
	text-align: center;
	color: #00142d;
	font-size: 14px;
	line-height: 16px;
	width: 130px;
	margin: auto;
	margin-top: 23px;
	margin-bottom: 23px;
	position: relative;
	cursor: pointer;
	&::after {
		content: '';
		position: absolute;
		height: 24px;
		width: 1px;
		background-color: #e0e0e0;
		top: 0;
		left: 0;
		top: -24px;
		left: 20%;
	}
	&.template::after,&.template::before{
		display: none;
	}
	&::before {
		content: '';
		position: absolute;
		height: 24px;
		width: 1px;
		background-color: #e0e0e0;
		left: 0;
		bottom: -24px;
		left: 20%;
		&.template{
			display: none;
		}
	}
}
.tab-content {
	.container {
		padding-bottom: 30px;
	}
}
.border-textarea {
	border: 1px solid grey;
	border: 1px solid #d8d8d8;
}
.form-box-wrapper {
	.tab-content-wrapper {
		form {
			width: 100%;
		}
		input[type='text'] {
			border-radius: 0;
			width: 100%;
			border-color: #d8d8d8;
			font-size: 14px;
			line-height: 14px;
			color: #4a4a4a !important;
		}
		input[type='email'] {
			border-radius: 0;
			width: 100%;
			border-color: #d8d8d8;
			font-size: 14px;
			line-height: 14px;
			color: #4a4a4a !important;
		}
		textarea {
			border: 1px solid lightgray !important;
			width: 100%;
			font-size: 14px;
			line-height: 14px;
			color: #4a4a4a !important;
			padding: 10px 8px;
			&:disabled{
				background-color: #F7F7F7 !important;
				color: #7A7A7A !important;
			}
		}
		select {
			border: 0;
			width: auto;
			color: #00142d;
			font-size: 14px;
			line-height: 14px;
			margin-bottom: 5px;
		}
	}
	label {
		font-size: 14px;
		line-height: 14px;
		color: #4a4a4a !important;
		&.form-check-label {
			font-weight: 300;
			padding-top: 12px;
			padding-left: 3px;
		}
	}
}

.journey-input {
	input.ant-input[type='text'] {
		width: calc(100% - 70px); // ensure a span with word "days" fits on right
	}
}

.check-box-wrapper {
	padding-top: 20px;
	.button-tab-detail {
		margin-left: 20px;
		margin-top: 3px;
		.button-name {
			padding: 0;
		}
		.filter-select {
			width: 145px !important;
		}
	}
}

.popUp {
	&.ant-modal {
		width: 622px !important;
		color: #000 !important;
	}
	.ant-modal-body {
		padding: 50px 35px 10px;
		text-align: center;
		.high-five {
			height: 50px;
			width: 62.03px;
		}
		.header {
			margin: 25px auto 10px;
			width: 70%;
		}
		p {
			font-size: 16px;
		}
		.row {
			margin: 0;
			margin-top: 43px;
			.form-group.edit-icon-box {
				margin: 0 50px;
				span.pen-icon {
					position: absolute;
					right: 50px;
					top: 50%;
				}
			}
		}
	}
	.ant-modal-footer {
		border: none;
		padding-bottom: 81px;
		text-align: center;
	}
	&.dark-theme{
		.ant-modal-content{
			background-color: #00142D;
			color: #ffffff;
		}
	}
}

.border-input {
	text-align: center;
	border: 1px solid #d9d9d9 !important;
}

.box-Shadow {
	border: 1px solid #e0e0e0;
	box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.5) !important;
}
