a,
a:hover {
  text-decoration: none;
}

.monitoring {
  height: 100%;
}

.profile-detail-box {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  padding-top: 120px;
  @include media-breakpoint-down(sm) {
    margin-top: 0px;
  }
  h1 {
    color: #00142d;
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
  }
  .company-link {
    color: #00142d;
    font-size: 14px;
    line-height: 16px;
    margin-top: 7px;
    cursor: pointer;
  }
}
.profile-detail-box .personal-detail-box {
  position: relative;
  padding-left: 30px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 180px;
    width: 1px;
    background: #979797;
    content: '';
  }
  .add-user {
    cursor: pointer;
    margin-top: 4px;
    .add-btn {
      height: 19px;
    }
    .text {
      display: inline;
      color: #ff6f47;
      font-size: 14px;
      line-height: 16px;
      margin-left: 7px;
    }
  }

  .detail-box {
    .action-btn {
      color: #00142d;
      font-weight: 600;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      &:nth-child(1) {
        font-weight: 600;
        margin-top: 5px;
      }
      &:nth-child(2) {
        font-weight: 400;
        word-break: break-all;
      }
    }
  }
}

.profile-detail-box .white-box {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.profile-detail-box .white-box .number {
  color: #00142d;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
}

.profile-detail-box .white-box p {
  margin-top: 15px;
  margin-bottom: 0;
}

.profile-detail-box .white-box .icon {
  img {
    height: 30px;
    width: 17.44px;
    margin-top: 5px;
    &.survey {
      height: 25px;
      width: 25px;
    }
    &.share {
      height: 25px;
      width: 17.74px;
    }
    &.referral {
      height: 25px;
      width: 22.58px;
    }
  }
}

.profile-detail-box .white-box .stats {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 40px;
  margin-left: 13px;
}

.detail-box {
  max-height: 412px;
  overflow: auto;
}

div.detail-box::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00142d;
  border-radius: 10px;
}

.fleetBox {
  text-align: left !important;
  box-sizing: border-box;
  margin: 0;
  box-shadow: none !important;
  border: 1px solid #dfe6ee !important;
  border-radius: 10px;
  padding: 20px 0px !important;
  justify-content: space-evenly;
  &.hideMargin {
    border: 0 !important;
    padding: 6px 0 15px 0 !important;
  }
  &.mapView {
    .title {
      margin: 0 !important;
    }
  }
  &.white-box {
    .seprator-none,
    .seprator-bottom {
      position: relative;
      padding-left: 25px;
    }
    .icon-wrap {
      position: absolute;
      left: 0;
      top: 38px;
    }
    .metrics-wrap {
      .metrics {
        display: flex;
        flex-direction: row;
      }
      .datum {
        margin: 6px 0;
        vertical-align: top;
        width: 50%;
        padding: 0 5px;
        cursor: pointer;
      }
      .metrics-left-side {
      }
      .metrics-right-side {
      }
    }
  }
  .title {
    line-height: 17px;
    margin-bottom: 10px !important;
    font-size: 14px;
    padding: 0;
    display: block;
  }
  img {
    height: 20px;
    width: 20px;
  }
  .row {
    margin: 0;
    text-align: center;
    &.cursor {
      cursor: pointer;
    }
  }

  .total {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    width: 50%;
    &.red {
      color: #ff6f47;
    }
    &.yellow {
      color: #ffd110;
    }
  }
  .total-account {
    color: #4a4a4a;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
  }
}

/* tab wrapper */

.tab-wrapper {
  width: 100%;
  text-align: right;
}
.tab-wrapper ul {
  margin: 60px 0 15px 0;
  padding: 0;
}

.tab-wrapper ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
  text-align: right;
  font-size: 14px;
  line-height: 20px;
  color: #7a7a7a;
  margin-right: 50px;
}
.tab-wrapper ul li a {
  color: #6a6a6a;
  text-decoration: none;
}
.tab-wrapper ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.tab-wrapper ul li a span {
  display: inline-block;
  margin-right: 6px;
}

.tab-wrapper ul li.inbox a .icon {
  background-image: url(../Assets/Images/inbox_icon.svg);
  width: 23px;
  height: 22px;
  background-size: 23px 22px;
  background-repeat: no-repeat;
}

.tab-wrapper ul li.home-owner a .icon {
  background-image: url(../Assets/Images/person_heart_gray.svg);
  width: 19px;
  height: 26px;
  background-size: 19px 26px;
  background-repeat: no-repeat;
}

.tab-wrapper ul li.sys-crm a .icon {
  background-image: url(../Assets/Images/sync-crm-icon.svg);
  width: 29px;
  height: 26px;
  background-size: 29px 26px;
  background-repeat: no-repeat;
}

.tab-wrapper ul li.edit-detail a .icon {
  background-image: url(../Assets/Images/edit-details-icon.png);
  width: 29px;
  height: 26px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  margin: 0;
}

.tab-wrapper ul li.delete a .icon {
  background-image: url(../Assets/Images/delete-icon.png);
  width: 23px;
  height: 23px;
  background-size: 15px 18px;
  background-repeat: no-repeat;
  margin: 0;
}

/**
 * [!] Ensure there is enough space at the bottom of (multiple) journeys menus so the last menu element is accessible
 */
.journeys-menu-parent {
  margin-bottom: 80px !important;
}

/* profile detail */
.installer-ui-container,
.profile-detail-wrapper,
.right-content-box h2 {
  .edit-link {
    position: relative;
    color: #9b9b9b;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    &::before {
      content: '';
      background-size: 14px 14px;
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 5px;
      margin-left: 10px;
    }
    &.inactive::before {
      background-image: url(../Assets/Images/edit-details-icon.svg);
    }

    &.active {
      color: #00142d;
      &::before {
        background-image: url(../Assets/Images/save_icon.svg);
      }
    }
  }
  .monitoring-profile-box {
    padding: 50px 50px 38px 50px;
    border-bottom: 1px solid #e9e9e9;
  }
  h2 {
    font-weight: 200;
    margin-bottom: 18px !important;
  }
}

.profile-detail-wrapper .monitioring-detail-box {
  display: flex;
  justify-content: space-between;
  .meter-type {
    width: 110px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.profile-detail-wrapper .monitioring-detail-box > div p:nth-child(1) {
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 26px;
}

.profile-detail-wrapper .monitioring-detail-box > div p:nth-child(2) {
  font-weight: normal;
  margin-bottom: 0;
}

.profile-detail-wrapper .monitioring-detail-box a.test-meter-link {
  margin-top: 30px;
  width: 220px !important;
}

.profile-detail-wrapper .monitioring-detail-box .form-section-wrapper label {
  margin-bottom: 0;
  margin-top: 28px;
  font-weight: 600;
}

.notify-box {
  margin-top: 1rem;
  position: relative;
  .forgot-password {
    margin: 10px auto;
    margin-left: 46px;
  }
}

.notify-box p:nth-child(1) {
  font-weight: 600;
  margin-bottom: 0;
}
.notify-box p:nth-child(2) {
  font-weight: normal;
}

/**
 * Idea is to standardize the margin and other layout styles used across installer section
 * and to support a standard layout shared across installer views...WIP
 * e.g. compares to `camp-dashboard-wrapper`, etc.
 */
div.installer-ui-wrapper {
  margin-top: 75px;
}

.profile-detail-wrapper .notification-doc-box {
  padding: 32px 0px 20px 0px;

  .activity-log {
    .month {
      color: #ff6f47;
      display: block;
    }
    .box-Shadow {
      margin: 10px 8px;
      padding: 10px 13px 0;
    }
    .dashboard-profile-image {
      top: -20px;
    }
    .log {
      margin-top: 5px;
      display: block;
      margin-left: 6px;
    }
    .note-log {
      display: inline-block;
      margin-left: 15px;
    }
    .note-icon {
      float: right;
      img {
        height: 15px;
        cursor: pointer;
        margin-left: 9px;
      }
    }
  }
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
  }
  .media h5 {
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0;
    line-height: 21px;
  }
  .media p {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 5px;
    font-weight: 400;
  }
  .installer-document-box {
    border-left: 1px solid #e9e9e9;
    padding-left: 30px;
    word-break: break-all;
  }
}
.doc-content {
  margin-top: 20px;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      color: #4a4a4a;
      font-size: 14px;
      line-height: 20px;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 18px;
      a {
        color: #4a4a4a;
        word-break: break-all;
        display: initial;
        span {
          display: block;
        }
      }
      .file {
        margin-bottom: 5px;
        font-weight: 300;
        line-height: 22px;
      }
      img {
        margin-right: 10px;
      }
    }
  }
}

/**
 * New styles for new layout
 */
.installer-view-wide {
  .ant-spin-nested-loading > div > .ant-spin {
    left: 100px;
  }
  .installer-ui-container {
    .form-submit-control {
      width: calc(100% - 352px);
      left: 352px;
      @include media-breakpoint-down(md) {
        left: 0;
        bottom: 76px;
        width: 100%;
      }
    }
  }
}

.installer-view {
  .installer-ui-container {
    .form-submit-control {
      width: calc(100% - 251px);
      left: 251px;
    }
  }
}

.installer-ui-container {
  padding-bottom: 30px; // create space between "footer" button controls and editable form pane
  .form-submit-control {
    margin: auto;
    z-index: 999;
    text-align: center;
    padding: 20px 0;
    position: fixed;
    bottom: 0px;
    right: 0;
    left: 251px; // for admin, and installer (narrow)
    background: #fff;
  }

  .installer-detail-section {
    .cloud-upload-img {
      position: absolute;
      background-size: 15px 15px;
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      margin-left: 10px;
      background-image: url('../Assets/Images/add_cloud.svg');
      cursor: pointer;
      right: 10px;
      top: 5px;
    }

    .file-upload {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 50px;
      opacity: 0;
      z-index: 99;
      cursor: pointer;
      &[disabled] {
        cursor: not-allowed;
      }
    }
  }

  img.tooltip-info {
    width: 16px;
    height: auto;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 4px; // assumes this sits adjacent to a label which has a 20px line-height.
  }

  section {
    label {
      font-family: 'HarmoniaSansProCyrRegular';
      font-size: 14px;
      line-height: 20px;
      color: #000;
    }
    .b-left {
      border-left: 1px solid #dfe6ee;
    }
    .p-bottom {
      padding-bottom: 25px;
    }
    .p-left {
      padding-left: 20px;
    }
    .p-top {
      padding-top: 20px;
    }

    &.standard-spacing {
      &.bordered {
        border: 1px solid #dfe6ee;
      }
      margin: 35px 0 0 40px;
      border-radius: 10px;
    }
    .content-section-box{
      padding-bottom:90px;
    }
    // for h2, etc.
    .header-form-cell {
      margin-bottom: 8px;
    }
    // minimum form control styling
    .default-form-cell {
    }

    // for structuring spacing, styling between form elements
    .padded-form-cell {
      margin-bottom: 35px;
    }

    .default-form-cell,
    .padded-form-cell {
      &.flex {
        display: flex;
      }

      .ant-select {
        max-width: 250px;
      }

      .ant-input {
        font-family: 'HarmoniaSansProCyrLight';
        padding: 0;
        color: rgba(0, 0, 0, 0.87) !important;
        border-color: #ccc !important;
        &.color-input {
          width: calc(100% - 30px);
          height: auto;
        }
        &:focus {
          border-color: #ffd110 !important;
        }
      }

      /**
       * Start: Margin/padding and related atomics to best layout form controls within the bootstrap grid
       */
      // for relative spacing between elements
      &.p-top-inline {
        padding-top: 20px;
      }

      &.m-r30 {
        margin-right: 30px;
      }

      &.p-r15 {
        padding-right: 15px;
      }
      &.p-l15 {
        padding-left: 15px;
      }

      &.p-r20 {
        padding-right: 20px;
      }
      &.p-l20 {
        padding-left: 20px;
      }

      .h-40 {
        height: 40px;
      }

      .h-60 {
        height: 60px;
      }

      .h-80 {
        height: 80px;
      }

      .w100 {
        max-width: 100px;
        display: block;
      }

      .w200 {
        width: 200px;
        display: inline-block;
      }
      .w300 {
        width: 300px;
        display: inline-block;
      }
      .w400 {
        width: 400px;
        display: inline-block;
      }
      .w500 {
        width: 500px;
        display: inline-block;
      }

      /**
       * End: Margin/padding and related atomics to best layout form controls within the bootstrap grid
       */

      .relative-wrap {
        position: relative;
        .ant-form-item {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto !important;
          .ant-form-item-control-input {
            min-height: auto;
          }
        }
        .adjacent {
          &.right {
            right: -45px;
            top: 3%;
          }
          position: absolute;
        }
        button.text-only {
          border: 0 none;
          background: transparent;
        }
        .file-upload-wrapper {
          button {
            z-index: 999;
          }
          .file-upload-ctrl {
            position: absolute;
            right: -8px;
            top: 0;
            left: 0;
            bottom: 0;
          }
          img {
            max-width: 150px;
            max-height: 45px;
            margin-top: 5px;
            display: block;
          }
        }
      }

      .inline-adjacent {
        display: inline-block;
        margin-left: 40px;
      }

      .color-view {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 15px;
        height: 15px;
      }
    }
  }
}

/* edit form */

.form-section-wrapper .form-wrap label {
  font-size: 14px;
  color: #9b9b9b;
  text-transform: none;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
.form-section-wrapper .form-wrap .caret-icon {
  position: relative;
}
.form-section-wrapper .form-wrap label.caret-icon:after {
  position: absolute;
  height: 12px;
  width: 12px;
  content: url(../Assets/Images/grey-down-arrow.svg);
  right: -20px;
  top: -3px;
}

.form-section-wrapper .form-control-wrap {
  position: relative;
}

.form-section-wrapper .form-control-wrap i {
  position: absolute;
  right: 0;
  top: 10px;
  display: none;
}

.form-section-wrapper .form-control-wrap .form-control {
  display: block;
  width: 100%;
  height: 30px;
  padding: 3px 10px;
  font-size: 14px;
  line-height: 16px;
  color: #495057;
  border: 0px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: inherit;
  position: relative;
}

.form-section-wrapper .form-section-box {
  border-bottom: 2px solid #f5f8fb;
}

.form-section-wrapper .form-section-box .form-container {
  border-right: 2px solid #f5f8fb;
  padding: 50px;
}
.form-section-wrapper .form-section-box .uplolad-container {
  padding: 50px;
  text-align: center;
}

.profile-detail-wrapper .form-section-wrapper .form-section-box .form-container h2,
.profile-detail-wrapper .form-section-wrapper .form-section-box .uplolad-container h2 {
  margin-bottom: 30px;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 63px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-wrap a {
  background: #00142d;
  min-height: 40px;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  display: block;
  height: 40px;
  width: 280px;
  margin: 0 auto;
  padding: 6px;
}

.solar-system-wrapper .form-container,
.electric-wrapper .form-container {
  padding: 50px;
  border-bottom: 2px solid #f5f8fb;
}
.assign-project-wrapper .form-container {
  padding: 50px;
}
.solar-system-wrapper .form-container h2,
.electric-wrapper .form-container h2,
.assign-project-wrapper .form-container h2 {
  margin-bottom: 30px;
}

.btn {
  font-size: 14px;
  border-radius: 10px !important;
  font-weight: bold;
  width: 150px !important;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: center;
  color: #00142d;
  @include media-breakpoint-down(md) {
    &.setting-btn {
      margin: 15px 15px 0 0 !important;
    }
  }
  &.auto-width {
    min-width: 150px;
    width: auto !important;
    padding: 0 10px;
  }
}

.btn-secondary,
.btn-secondary:not(:disabled):not(.disabled):active {
  border-color: #ffd110;
  background: white;
  color: #00142d;
  &.dark-theme-btn{
    background:#00142D;
    color: #ffd110;
  }
  &:hover {
    background: #ffd110;
    color: #00142d;
    border-color: #ffd110;
  }
  &:focus {
    background: #fff;
    color: #00142d;
    border-color: #ffd110;
  }
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled):active {
  border-color: #ffd110;
  background: #ffd110;
  color: #00142d;
  &:hover,
  &:focus {
    border-color: #ffdb40 !important;
    color: #00142d;
    font-size: 14px;
    background: #ffdb40 !important;
  }
  &.w-200 {
    width: 200px !important;
  }
}

@media screen and (max-width: 800px) {
  .profile-detail-wrapper .monitioring-detail-box > div {
    padding-right: 20px;
  }
  .profile-detail-wrapper .monitioring-detail-box > div p:nth-child(2) {
    word-break: break-all;
  }
  .notify-box::after {
    right: 0;
  }

  .profile-detail-wrapper h2 {
    font-size: 25px;
  }
  .upload-btn-wrapper .btn,
  .upload-wrap a {
    width: 200px;
  }
}

@media screen and (max-width: 767px) {
  .button-box .btn {
    margin-bottom: 15px;
  }
  .upload-btn-wrapper .btn,
  .upload-wrap a {
    width: 150px;
  }
  .assign-project-wrapper .form-container,
  .form-section-wrapper .form-section-box .form-container,
  .form-section-wrapper .form-section-box .uplolad-container,
  .profile-detail-wrapper .monitoring-profile-box,
  .solar-system-wrapper .form-container,
  .electric-wrapper .form-container,
  .assign-project-wrapper .form-container,
  .profile-detail-wrapper .monitoring-profile-box {
    padding: 20px;
  }

  .assign-project-wrapper .form-container .form-wrap,
  .form-section-wrapper .form-section-box .form-container .form-wrap,
  .form-section-wrapper .form-section-box .uplolad-container .form-wrap,
  .profile-detail-wrapper .monitoring-profile-box .form-wrap,
  .solar-system-wrapper .form-container .form-wrap,
  .electric-wrapper .form-container .form-wrap,
  .assign-project-wrapper .form-container .form-wrap,
  .profile-detail-wrapper .monitoring-profile-box .form-wrap {
    padding-left: 20px;
  }

  .profile-detail-box .white-box .engagement-scroe-box .icon,
  .profile-detail-box .white-box .referrals-box .icon,
  .profile-detail-box .white-box .surveys-box .icon,
  .profile-detail-box .white-box .social-shares-box .icon {
    display: none;
  }
  .profile-detail-box .personal-detail-box,
  .profile-detail-box .personal-detail-box .detail-box p,
  .profile-detail-box,
  .profile-detail-box .white-box {
    font-size: 12px;
    line-height: 14px;
  }

  .col-xs {
    width: 50%;
  }

  .profile-detail-box .white-box {
    margin-top: 35px;
    justify-content: inherit;
    align-items: inherit;
    height: 90px;
  }
  .profile-detail-box .white-box div {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .name-adddres-box {
    padding-left: 30px;
  }

  .profile-detail-box .personal-detail-box {
    padding-left: 20px;
  }

  .profile-detail-box .white-box .content {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
  }
  .profile-detail-box .white-box .number {
    margin-top: 0;
  }
  .profile-detail-wrapper .monitioring-detail-box {
    display: block;
    padding: 0 20px;
  }

  .tab-wrapper ul li .icon {
    display: none;
  }

  .tab-wrapper ul li {
    font-size: 13px;
    margin-right: 0;
  }
  .tab-wrapper ul {
    margin-top: 40px;
  }

  .notify-box::after {
    content: inherit;
    position: inherit;
  }
  .profile-detail-wrapper .monitioring-detail-box > div.test-meter-box {
    width: 220px;
    margin: 0 auto;
    padding: 0px;
  }
  .profile-detail-wrapper .monitioring-detail-box > div.test-meter-box .test-meter-link {
    padding: 10px;
  }

  .profile-detail-wrapper .notification-doc-box {
    padding: 0 32px;
  }
  .profile-detail-wrapper .monitoring-profile-box {
    border-bottom: 10px solid #f5f8fb;
  }
}

/* switch */
.on-off-case {
  display: flex;
  width: 200px;
  margin-top: 10px;
}

.no-padding-box {
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: -25px;
  }
}

@include media-breakpoint-down(sm) {
  .tab-wrapper ul li a span {
    margin-right: 15px;
  }
}
.conection {
  height: 37px;
  width: 247px;
  color: #4a4a4a;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 28px;
}

.monitoring-profile-box .monitioring-detail-box button.test-meter-link {
  margin-top: 45px;
  width: 220px !important;
}

.document-popUp {
  .header-popUp {
    color: #00142d;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .search-header {
    height: auto;
    margin: 20px 0;
    color: #5f5a5a;
    .filter-option {
      display: inline;
      font-size: 13px;
      cursor: pointer;
      color: #ff6f47;
      &:hover {
        color: #5f5a5a;
        font-weight: 400;
      }
      &.selectedFolder {
        color: #5f5a5a;
      }
      i {
        margin: 0 5px;
      }
    }
  }
  .syn-search-box {
    border: 1px solid #a5a2a2;
    width: 100%;
    padding: 0 5px;
    color: black;
    .form-control {
      border-radius: 0;
      height: 25px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 200;
      padding-right: 30px;
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #e9e9e9;
      color: #9b9b9b;
      border-radius: 0;
      padding: 0px;
      width: 25px;
      height: 25px;
    }
    svg {
      margin-top: -5px;
    }
  }
  .select-folder-wrapper {
    h3 {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #9b9b9b !important;
      margin: 26px 0 8px 0;
      padding: 0;
    }
    .btn-wrapper {
      text-align: center;
      margin-top: 30px;
    }
    .select-folder-box {
      overflow-y: auto;
      max-height: 235px;
      padding: 0 10px;
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          .icon {
            background-image: url(../Assets/Images/folder-grey.png);
            width: 18px;
            height: 18px;
            background-size: 18px;
            display: inline-block;
            margin-right: 15px;
          }
          .folderName {
            font-size: 12px;
            line-height: 36px;
            font-weight: 400;
            color: #000000;
            align-items: center;
            margin: 0;
            &:hover {
              color: #00142d;
              cursor: pointer;
            }
          }
          ul {
            padding-left: 37px;
          }
          .selectedFolder {
            color: #00142d;
            font-size: 12px;
            line-height: 36px;
            font-weight: 400;
            align-items: center;
            margin: 0;
          }
        }
      }
    }
  }
}

i.anticon.anticon-check.ant-select-selected-icon {
  display: none !important;
}

/**
 * nesting fixes
 */
ul.ant-menu ul.ant-menu {
  padding-left: 20px !important;
  li {
    div {
      padding-left: 26px !important;
    }
    ul.ant-menu {
      padding-left: 0 !important;
    }
  }
}

.ant-menu-inline-collapsed {
  &-tooltip {
    display: none;
  }
}

body.header-fleet {
  .dashboard-nav-wrapper .bg-light {
    border-bottom-color: transparent;
  }
  .map-wrapper {
    padding-left: 20px;
  }
}

body.installer-view-wide {
  width: calc(100% - 350px);
  margin-left: 350px;
  .nav-header-wrapper.dashboard-nav-wrapper.position-fixed {
    margin-left: -350px;
  }
}

body.installer-view {
  width: calc(100% - 100px);
  margin-left: 100px;
  .nav-header-wrapper.dashboard-nav-wrapper.position-fixed {
    margin-left: -100px;
  }
}

body.installer-view-wide,
body.installer-view {
  overflow-x: hidden;

  .navbar-brand.default {
    display: none;
  }

  .nav-header-wrapper.dashboard-nav-wrapper.position-fixed {
    height: 80px;
  }

  // yellow CTA, top...
  .admin-view {
    font-size: 14px;
    font-weight: 400;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-left: 0px;
  }
  .nav-header-wrapper.dashboard-nav-wrapper.position-fixed {
    @include media-breakpoint-down(md) {
      margin-left: 0px;
    }
    nav.navbar.navbar-expand-lg.navbar-light.bg-light {
      padding-left: 120px;
      height: inherit;
    }
  }
}

.seprator-right {
  border: none !important;
  border-right: 1px solid #dfe6ee !important;
  box-sizing: border-box;
}

.seprator-bottom {
  border: none !important;
  border-bottom: 1px solid #dfe6ee !important;
  box-sizing: border-box;
}

.ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  font-size: 14px;
}

.link-Color {
  color: #ff6f47 !important;
  &.hover-link {
    border-color: #ff6f47;
    &:hover {
      color: white !important;
      background-color: #ff6f47;
    }
  }
}

.map-filter-box {
  position: absolute;
  background-color: #fff;
  top: 0; // the inline search in this container replaces the header search box
  z-index: 2;
  box-sizing: border-box;
  width: 232px; // this, plus padding, matches nav width for secondary navigation, e.g. company settings

  .fleet-sider {
    position: absolute;
    top: 90px;
    z-index: 4;
    height: 26px;
    width: 26px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-image: url(../Assets/Images/fleet_LFTcollapse_icon.svg);
    right: -13px !important;
    &.hide {
      left: -10px !important;
      background-image: url(../Assets/Images/fleet_RTcollapse_icon.svg);
    }
  }

  .detail-box {
    max-height: calc(100vh - 202px);
    .seprator-bottom :hover {
      background-color: #f7f7f7;
    }
    .homeowner-name {
      font-family: 'HarmoniaSansProCyrBold';
    }
    font-family: 'HarmoniaSansProCyrLight';
  }

  .search-wrap {
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    display: inline-flex;
    position: relative;
    width: 210px;
    height: 32px;
    margin-top: 22px;
    padding: 4px 20px 5px 13px;
    background-color: #fbfbfb;

    img {
      margin-top: 5px;
    }

    input {
      color: #4a4a4a !important;
      padding-left: 12px !important;
      width: calc(100% - 25px);
      padding: 0;
      margin-top: -3px;
    }

    &.fleet {
      input {
        margin-top: 4px;
        margin-left: 9px;
      }
      img {
        height: 15px;
      }
    }
    &.inbox {
      input {
        margin-top: 4px;
        margin-left: 9px;
      }
      img {
        height: 15px;
      }
    }
  }

  .menuOption {
    padding: 25px 0 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 210px;

    .ant-dropdown-menu-item {
      span {
        color: #313541;
      }
    }

    .ant-dropdown-trigger {
      height: 35px;
      border: 1px solid $silver1;
      border-radius: 10px;
      padding: 8px 20px;
      color: #7a7a7a;
      line-height: 17px;
      margin: 6px 0;
      cursor: pointer;
      width: 200px;

      .dropdown-icon {
        float: right;
        margin-top: 2px;
        &.fa-times {
          color: $silver1;
          font-size: 11px;
          margin-top: 4px;
          cursor: pointer !important;
        }
      }
    }
  }
  .no-result-block {
    width: 210px;
    color: #7a7a7a;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin: 66px auto;
  }
  .text {
    text-align: left;
    line-height: 17px;
    font-size: 14px;
    padding: 0;
    padding-left: 5px;
  }
  .img {
    height: 29px;
    width: 29px;
  }
}

.ant-dropdown {
  border: 1px solid #dfe6ee;
  box-shadow: 0 2px 6px 0 #b0bac5 !important;
}

.font-block {
  &.font-light,
  .font-light {
    font-family: 'HarmoniaSansProCyrLight';
  }
  &.font-regular,
  .font-regular {
    font-family: 'HarmoniaSansProCyrRegular';
  }
}

.fleet-viewType {
  height: 33px;
  width: 156px;
  border-radius: 13px;
  background-color: black;
  color: white;
  padding: 6px 4px;
  float: right;
  margin-top: 12px;
  margin-right: 12px;
  &.mapView {
    position: fixed;
    top: 120px;
    right: 80px;
    z-index: 2;
  }
  input {
    display: none;
  }
  #bckgrnd,
  .labels {
    width: 50%;

    text-align: center;
    // padding-top: 10px;
    cursor: pointer;
  }
  .labels {
    border-radius: 13px;
    line-height: 22px;
    font-size: 12px;
    font-weight: bold;
    &.checked {
      background-color: white;
      color: black;
    }
  }
  #bckgrnd {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 13px;
    z-index: -1;
    transition: left linear 0.3s;
  }
  #rad1:checked ~ #bckgrnd {
    left: 0;
    color: #000 !important;
  }
  #rad2:checked ~ #bckgrnd {
    left: 50%;
    color: #000 !important;
  }
}

.fleet-homeownerInfo {
  width: 400px;
  font-size: 14px;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.xAxisWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25%;
  padding-right: 2%;

  .innerChild {
    text-align: center;
    margin: 0 1%;
    .prod {
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
}

.areachart-wrapper {
  display: flex;

  .chart-outer {
    width: calc(100% - 30px);
  }

  .custom-axis {
    flex-direction: column;
    display: flex;
    width: 45px;

    span {
      font-size: 14px;
      font-weight: 300;
      height: calc(33.333% + 40px);
      text-align: right;
      padding-top: 40px;
    }
  }
}

.areachart {
  border: 0.8px solid #e0e0e0;
  margin-top: -35px;
  position: relative;
  .area {
    opacity: 0.8 !important;
  }
  .area-chart {
    margin-top: 35px;
    padding-left: 10px;

    /**
     * This targets nodes within the svg element
     */
    .wrapper {
      .axis {
        display: none;
      }
    }
  }

  .empty-chart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .fa {
      font-size: 48px;
      color: $silver1;
    }
    p {
      color: #7a7a7a;
      font-size: 15px;
      font-weight: bold;
    }
  }
}

.alert-value {
  width: 65px;
}

.inbox-text-notification {
  padding-bottom: 10px;
}

.inbox-fleet-stats {
  padding-left: 5px;

  &.yellow {
    color: #FFD110
  }

  &.red {
    color: red
  }
}

.inbox-fleet-icon {
  padding-left: 2px;
  height: 25px;
  width: 18px;
}


input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}
