.dashobard-main-wrapper .banner-graphic {
  background-size: cover;
  height: 641px;
  background-color: #4a4a4a;
  opacity: 1;
  overflow: hidden;
  position: relative;
}
.modal-content {
  width: 90%;
  margin:0 auto;
  max-width: 750px; 
}

/* new add  css for video */
.dashobard-main-wrapper .video-graphic {
  position: absolute;
  background-color: black;
  width: 100%;
  overflow: hidden;
}

.dashobard-main-wrapper .video-graphic {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
/* new add  css for video */
.dashobard-main-wrapper {
  overflow: hidden;
  color: #4a4a4a;
  background: #f5f8fb;
}

.dashobard-main-wrapper .banner-content-box {
  width: 545px;
  height: 600px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashobard-main-wrapper .banner-content-box h1 {
  color: #ffffff;
  font-size: 55px;
  font-weight: 300;
  line-height: 70px;
  text-align: center;
  text-shadow: 0 2px 4px #4a4a4a;
}
.dashobard-main-wrapper .banner-content-box p {
  color: #ffffff;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-top: 15px;
  font-weight: 300;
}
.dashobard-main-wrapper .banner-content-box .video-link .watch-video {
  background-color: #00142D;
  height: 32px;
  width: 170px;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  margin-top: 26px;
  display: inline-block;
  transition: 0.6s all;
  border: 2px solid #00142D;
}
.dashobard-main-wrapper .banner-content-box .video-link .watch-video:hover {
  text-decoration: none;
  border: 2px solid #ffffff;
  background-color: #ffffff;
  color: #00142D;
}

.dashobard-main-wrapper .banner-content-box .video-link {
  height: 61px;
}

.dashobard-main-wrapper .login {
  border: 1px solid #ffffff!important;
  width: 100px;
  height: 25px;
  display: inline-block;
  margin: 20px;
  color: #ffffff!important;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  transition: 0.3s all;
  background-color: transparent!important;
}
.dashobard-main-wrapper .login:hover {
  background-color: #00142D;
  color: #ffffff;
  text-decoration: none;
  border: 1px solid #00142D;
}
.dashobard-main-wrapper .bodhi-logo img {
  width: 296px;
}

/* bodhi build */
.dashobard-main-wrapper .bodhi-build-for-you-wrapper {
  margin-bottom: 60px;
}
.dashobard-main-wrapper .bodhi-build-for-you-wrapper h2 {
  color: #00142D;
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin: 50px 0;
}
.dashobard-main-wrapper .bodhi-build-for-you-wrapper .boxes { 
  padding-top: 10px;
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
  height: 100%;
  img.mobile{
    display: none;
  }
  img.web{
    display: inline;
  }
}

.dashobard-main-wrapper .bodhi-build-for-you-wrapper .boxes.box-first::after,
.dashobard-main-wrapper .bodhi-build-for-you-wrapper .boxes.box-second::after {
  height: 90%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 50px;
  content: "";
  background-color: #00142D;
}

.dashobard-main-wrapper .bodhi-build-for-you-wrapper .icon {
  margin-bottom: 40px;
}
.dashobard-main-wrapper .bodhi-build-for-you-wrapper .icon img {
  width: 60px;
  height: 60px;
}
.dashobard-main-wrapper .bodhi-build-for-you-wrapper h3 {
  font-weight: 700;
  font-size: 18px;
}
.dashobard-main-wrapper .bodhi-build-for-you-wrapper p {
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  color: #4a4a4a;
}

/* progress graphic */
.dashobard-main-wrapper .progress-graphic-box {
  width: 712px;
  margin: 140px auto;
 
}


.dashobard-main-wrapper .progress-graphic-box img {
  width: 100%;
}

.dashobard-main-wrapper .footer-wrapper {
  text-align: center;
  margin: 40px;
  font-size: 18px;
  line-height: 26px;
}
.dashobard-main-wrapper .footer-wrapper a {
  color: #00142D;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

#watchvideo .close {
  padding: 5px 10px;
  border: 1px solid grey;
  position: absolute;
  top: -17px;
  right: -16px;
  background-color: white;
  opacity: 1;
  border-radius: 50% !important;
  z-index: 20;
}

#watchvideo .modal-dialog {
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 992px) {
  #watchvideo .modal-dialog {
    max-width: 95%;
    width: 95%;
    margin: 0 auto;
  } 
}

@media screen and (max-width: 767px) {
  /* */
  .embed-responsive {
    height: 100% !important;
  }
 .bodhi-build-for-you-wrapper .col-md-4 {
    width: 100%;
  }
  #watchvideo {
    padding: 0;
  }
  #watchvideo .modal-body {
    width: 100%;
    padding: 0;
  }
  #watchvideo .modal-dialog {
    margin: 0 auto;
  }
  #watchvideo .close {
    top: -15px;
    right: 0;
  }

  .dashobard-main-wrapper .bodhi-build-for-you-wrapper .boxes.box-first::after,
  .dashobard-main-wrapper .bodhi-build-for-you-wrapper .boxes.box-second::after {
    height: 0px;
    width: 0px;
    position: inherit;
  }

  .dashobard-main-wrapper .progress-graphic-box {
    width: 80%;
    margin: 40px auto;
  }
  .dashobard-main-wrapper .bodhi-build-for-you-wrapper p,
  .dashobard-main-wrapper .bodhi-build-for-you-wrapper h3,
  .dashobard-main-wrapper .footer-wrapper {
    font-size: 16px;
    line-height: 20px;
  }
  
  .dashobard-main-wrapper .bodhi-build-for-you-wrapper h2 {
    font-size: 30px;
    margin: 30px 0;
  }
  .dashobard-main-wrapper .bodhi-build-for-you-wrapper .boxes {
    text-align: center;
    width: 96%;
    padding: 0;
    img.mobile{
      display: inline;
    }
    img.web{
      display: none;
    }
  }
  .dashobard-main-wrapper .footer-wrapper {
    margin: 70px 0 30px;
  }
  .modal-content {
    width: 100%;
    margin:0 auto;
  }
}
@media screen and (max-width: 800px) {
  .dashobard-main-wrapper .banner-content-box {
    width: 100%;
    padding: 20px;
    height: 380px;
  }
  .dashobard-main-wrapper .banner-graphic {
    height: 470px;
  }
  .dashobard-main-wrapper .banner-content-box h1 {
    font-size: 36px;
    line-height: 40px;
  }
  .dashobard-main-wrapper .banner-content-box p {
    font-size: 18px;
    line-height: 22px;
  }
  .dashobard-main-wrapper .bodhi-logo img {
    width: 190px;
  }
  .dashobard-main-wrapper .banner-content-box .watch-video {
    margin-top: 15px;
  }
  .modal-content {
    width: 100%;
    margin:0 auto;
  }
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0rem !important;
}