.success-account-wrapper {
    width: 622px;
    margin: 0 auto;
    padding-top: 166px;
    margin-bottom: $marg80;

    @include media-breakpoint-down(md) {
        width: 100%;

    }
}

.success-account-account-box {
    padding: $padd70 $padd40;
}

.tick-box {
    margin-bottom: $marg17;
}

.success-account-account-box p {
    font-size: $font18;
    color: black;
    line-height: 26px;
    font-weight: 300;

}
.success-account-account-box h3{
    font-weight: 400;

}

.footer-btn {
    margin-top: $marg30;
}