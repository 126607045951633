$robotofont:'Roboto',
sans-serif;

$montfont:'Montserrat',
sans-serif;


$font45: 2.8125rem; //45
$font24: 1.5rem; //24 px
$font18: 1.125rem; //18px
$font17: 1.0625rem; //17px
$font22: 1.375rem; //22px
$font16: 1rem; //16px
$font14: 0.875rem; //14px
$font15: 0.9375rem; //15px
$font26: 1.625rem; //26 px
$font20: 1.25rem; //20 px
$font30: 1.875rem; //30 px
$font20: 1.25rem; //20 px
$font12: 0.75rem; //12px
$font35: 2.1875rem; //35
$font22: 1.375rem; //22
$font13: 0.8125rem; //13px
$font60: 60px;

//color //
$labelborder: #e9e9e9;
$labelcolor: #9b9b9b;
$yellowbg: #ffcc00;
$error:#d0021b;
$pmbackground: #f5f8fb;
$ltblue: #33ccff;
$yell2:#f5a623;
$green:#7ed321;
$moff:#9013fe;
$moff2:#bd10e0;
$ltgrey1:#c3d0dc;
$ltgrey2:#aeb3c0;
$dkgrye:#4a4a4a;
$ltblue2:#00142D;
$ltgrey3:#e0e0e0;
$blackbrd:rgba(0, 0, 0, 0.12);
$black8:rgba(0, 0, 0, 0.87);
$blue3: #22c0fc;
$grey4: #979797;
$grey5 :#f9f9f9;
$greyLight: #f4f7fa;
$silver1: #CCCCCC;


// padding
$padd0: 0px;
$padd2: 2px;
$padd3: 3px;
$padd4: 4px;
$padd5: 5px;
$padd6: 6px;
$padd7: 7px;
$padd8: 8px;
$padd10: 10px;
$padd11: 11px;
$padd12: 12px;
$padd15: 15px;
$padd16: 16px;
$padd20: 20px;
$padd25: 25px;
$padd28: 28px;
$padd30: 30px;
$padd35: 35px;
$padd40: 40px;
$padd44: 40px;
$padd48: 48px;
$padd45: 45px;
$padd50: 50px;
$padd55: 55px;
$padd60: 60px;
$padd70: 70px;
$padd72: 72px;
$padd75: 75px;
$padd80: 80px;
$padd85: 85px;
$padd90: 90px;
$padd100: 100px;

//margin
$marg0: 0px;
$marg2: 2px;
$marg3: 3px;
$marg5: 5px;
$marg6: 6px;
$marg8: 8px;
$marg11: 11px;

$marg12: 12px;
$marg15: 15px;
$marg16: 16px;
$marg17: 17px;
$marg10: 10px;
$marg20: 20px;
$marg23: 23px;
$marg25: 25px;
$marg28: 28px;

$marg30: 30px;
$marg35: 35px;
$marg40: 40px;
$marg50: 50px;
$marg60: 60px;
$marg64: 64px;
$marg70: 70px;
$marg75: 75px;
$marg80: 80px;
$marg86: 86px;
$marg90: 90px;
$marg100: 100px;
$marg120: 120px;
$marg125: 125px;

$marg-top-70:70px;


$breakpoints:  ('large': (max-width: 1200px ), 'x-large': (max-width: 1400px)) !default;


@mixin respond-to($breakpoint) {

    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {

        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}